<template>
  <div class="toggable_box" :class="boxClass">
    <div>
      <h2 class="toggable_box_header">
        <button class="btn" @click="closeWindow">
          <i class="fa fa-arrow-right"></i>
        </button>
        <span>{{ title }}</span>
      </h2>
      <hr />
      <div class="toggable_box_content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  props: {
    value: Boolean,
    title: String,
    size: {
      type: String,
      default: 'md',
    },
  },
  data() {
    return {
      opened_window: false,
    };
  },
  methods: {
    closeWindow() {
      this.opened_window = false;
    },
  },
  computed: {
    boxClass() {
      const boxClass = {};
      boxClass.opened_window = this.opened_window;
      boxClass[this.box_width_class] = true;
      return boxClass;
    },
    box_width_class() {
      return `box_size_${this.size}`;
    },
  },
  watch: {
    value(newV) {
      this.opened_window = newV;
    },
    opened_window() {
      this.$emit('input', this.opened_window);
    },
  },
  mounted() {
    this.opened_window = this.value;
  },
};
</script>
<style type="text/css" scoped>
.toggable_box {
  position: absolute;
  right: 0;
  top: 0;
  width: 0px;
  transition: 0.4s;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px 0px 0px 8px;
  padding: 0px;
  z-index: 1;
  box-shadow: 0px 0px 13px 5px #ddd;
  opacity: 0;
}
.opened_window {
  width: 0px;
  padding: 10px;
  opacity: 1;
}
.opened_window.box_size_sm {
  width: 300px;
}
.opened_window.box_size_md {
  width: 300px;
}
.opened_window.box_size_lg {
  width: 300px;
}
@media (min-width: 601px) {
  .opened_window.box_size_sm {
    width: 350px;
  }
  .opened_window.box_size_md {
    width: 600px;
  }
  .opened_window.box_size_lg {
    width: 900px;
  }
}
@media (min-width: 601px) {
  .opened_window {
    width: 700px;
  }
}

.toggable_box_header {
  text-align: center;
}
.toggable_box_header span {
  font-size: 1.4rem;
  font-weight: 700;
}
.toggable_box_header button {
  position: absolute;
  left: 0;
  top: 10px;
}

</style>
