<template>
  <div class="row mb-20">
    <div class="col right">
      <button class="btn btn-primary btn-standard-color" @click="search">
        <i class="fa fa-search"></i>
      </button>
      <button class="btn btn-primary btn-standard-color" @click="add">
        <i class="fa fa-plus"></i>
      </button>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  methods: {
    search() {
      this.$emit('search');
    },
    add() {
      this.$emit('add');
    },
  },
};
</script>
<style type="text/css">
button {
  margin-left: 10px;
}
</style>
