<template>
  <div class="form-row">
    <div class="col right">
      <button class="btn btn-primary" @click="emit('restart_filter')">
        Reiniciar filtro
      </button>
      <button class="btn btn-secondary" @click="emit('cancel')">
        Cancelar
      </button>
      <button class="btn btn-success" @click="emit('filter')">Filtrar</button>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  methods: {
    emit(event) {
      this.$emit(event);
    },
  },
};
</script>
<style type="text/css" scoped>
button {
  margin-left: 10px;
}
</style>
