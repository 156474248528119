<template>
  <div class="form-row">
    <div class="col right">
      <button class="btn btn-secondary" @click="emit('cancel')">
        Cancelar
      </button>
      <button class="btn btn-primary" @click="emit('process')">
        {{ this.process_text }}
      </button>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  props: {
    process_text: {
      type: String,
      default: 'OK',
    },
  },
  methods: {
    emit(event) {
      this.$emit(event);
    },
  },
};
</script>
<style type="text/css" scoped>
button {
  margin-left: 10px;
}
</style>
