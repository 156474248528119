<template>
  <div class="paginator">
    <div class="row">
      <div class="col-12 center">
        <button
          class="btn"
          v-for="(button, index) in buttons"
          :key="index"
          :class="button.class"
          @click="paginate(button.page)"
        >
          {{ button.caption }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['current', 'pages'],
  data() {
    return {
      buttons: [],
      settings: {
        neighborPages: 2,
      },
      simple_button: {
        class: '',
        caption: '',
        page: 0,
      },
    };
  },
  methods: {
    paginate(page) {
      if (page > 0) this.$emit('paginate', page);
    },
    createButtons() {
      this.buttons = [];
      if (!this.pages) return;

      this.createPreviousButton();
      this.createFirstPageButton();
      this.createFirstIntervalButton();
      this.createLeftNeighborButton();
      this.createActiveButton();
      this.createRightNeighborButton();
      this.createLastIntervalButton();
      this.createLastPageButton();
      this.createNextButton();
    },
    createPreviousButton() {
      if (this.current !== 1) {
        this.buttons.push({
          class: 'standard',
          caption: '<',
          page: this.current - 1,
        });
      }
    },
    createFirstPageButton() {
      if (this.current !== 1) {
        this.buttons.push({
          class: 'standard',
          caption: '1',
          page: 1,
        });
      }
    },
    createFirstIntervalButton() {
      if (this.current - this.settings.neighborPages - 2 > 0) {
        this.buttons.push({
          class: 'disabled',
          caption: '...',
          page: 0,
        });
      }
    },
    createLeftNeighborButton() {
      let buttonsToCreate = this.current - 1 - 1;

      if (buttonsToCreate <= 0) return;

      if (buttonsToCreate > this.settings.neighborPages) {
        buttonsToCreate = this.settings.neighborPages;
      }

      const firstButton = this.current - buttonsToCreate;

      for (let ind = firstButton; ind < this.current; ind += 1) {
        this.buttons.push({
          class: 'standard',
          caption: ind,
          page: ind,
        });
      }
    },
    createActiveButton() {
      this.buttons.push({
        class: 'active',
        caption: this.current,
        page: 0,
      });
    },
    createRightNeighborButton() {
      let buttonsToCreate = this.pages - this.current - 1;

      if (buttonsToCreate <= 0) return;

      if (buttonsToCreate > this.settings.neighborPages) {
        buttonsToCreate = this.settings.neighborPages;
      }

      const firstButton = this.current + 1;

      for (
        let ind = firstButton;
        ind <= this.current + buttonsToCreate;
        ind += 1
      ) {
        this.buttons.push({
          class: 'standard',
          caption: ind,
          page: ind,
        });
      }
    },
    createLastIntervalButton() {
      const intervalButtons = this.pages - this.current - this.settings.neighborPages - 1;

      if (intervalButtons > 0) {
        this.buttons.push({
          class: 'disabled',
          caption: '...',
          page: 0,
        });
      }
    },
    createLastPageButton() {
      if (this.current !== this.pages) {
        this.buttons.push({
          class: 'standard',
          caption: this.pages,
          page: this.pages,
        });
      }
    },
    createNextButton() {
      if (this.current !== this.pages) {
        this.buttons.push({
          class: 'standard',
          caption: '>',
          page: this.current + 1,
        });
      }
    },
  },
  watch: {
    current() {
      this.createButtons();
    },
    pages() {
      this.createButtons();
    },
  },
  mounted() {
    this.createButtons();
  },
};
</script>
